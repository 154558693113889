<template>
  <div>
    <div class="tw-bg-red-100">
      <ml-container>
        <div class="tw-pb-6 tw-flex tw-flex-col sm:tw-flex-row tw-items-center">
          <div class="tw-flex tw-justify-center tw-mb-3 tw-mr-0 sm:tw-mb-0 sm:tw-mr-8">
            <div class="tw-w-36 tw-h-36 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-relative">
              <v-avatar size="144" class="tw-w-36 tw-h-36 tw-relative">
                <v-img :src="user.avatar"></v-img>
              </v-avatar>
              <Avatar
                activator-class="tw-absolute tw-right-0 tw-bottom-0 tw-text-white tw-bg-gray-n4 tw-w-12 tw-h-12" />
            </div>
          </div>
          <div class="tw-text-center sm:tw-text-left">
            <h1 class="tw-font-semibold tw-text-lg tw-text-black tw-leading-relaxed tw-mb-2">{{ user.name }}</h1>
            <p class="tw-mb-0 tw-text-sm tw-text-gray-n3 tw-leading-relaxed">
              {{ user.email }}
            </p>
          </div>
          <div class="tw-self-start tw-ml-auto tw-pt-4">
            <!--
              <a href="#" class="tw-font-semibold tw-flex tw-items-center tw-leading-relaxed tw-text-gray-n4">
                <edit2-icon class="tw-h-5 tw-w-5 tw-mr-2" />
                Edit profile info
              </a>
              -->
          </div>
        </div>
      </ml-container>
    </div>
    <ml-container>
      <div class="tw-border-b tw-mt-8 tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
        <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
          <a @click.prevent="activeTab = 'event'" href="#"
            :class="[activeTab === 'event' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']">
            <span>{{$t('user.navbar.my_event')}}</span>
          </a>
          <a @click.prevent="activeTab = 'personal-address'"
            :class="[activeTab === 'personal-address' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']"
            href="#">
            <span>{{$t('user.navbar.address')}}</span>
          </a>
        </nav>
      </div>
    </ml-container>
    <div v-if="loading">
      <svg class="tw-mx-auto tw-animate-spin tw-my-40" width="56" height="56" viewBox="0 0 56 56" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1731_14504)">
          <path opacity="0.25"
            d="M28 0C20.5739 0 13.452 2.94999 8.20101 8.20101C2.94999 13.452 0 20.5739 0 28C0 35.4261 2.94999 42.548 8.20101 47.799C13.452 53.05 20.5739 56 28 56C35.4261 56 42.548 53.05 47.799 47.799C53.05 42.548 56 35.4261 56 28C56 20.5739 53.05 13.452 47.799 8.20101C42.548 2.94999 35.4261 0 28 0V0ZM28 7C33.5695 7 38.911 9.21249 42.8492 13.1508C46.7875 17.089 49 22.4305 49 28C49 33.5695 46.7875 38.911 42.8492 42.8492C38.911 46.7875 33.5695 49 28 49C22.4305 49 17.089 46.7875 13.1508 42.8492C9.21249 38.911 7 33.5695 7 28C7 22.4305 9.21249 17.089 13.1508 13.1508C17.089 9.21249 22.4305 7 28 7Z"
            fill="#E14D4D" />
          <path
            d="M28 0C35.4261 0 42.548 2.94999 47.799 8.20101C53.05 13.452 56 20.5739 56 28H49C49 22.4305 46.7875 17.089 42.8492 13.1508C38.911 9.21249 33.5695 7 28 7V0Z"
            fill="#E14D4D" />
        </g>
        <defs>
          <clipPath id="clip0_1731_14504">
            <rect width="56" height="56" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </div>
    <div v-else>
      <template v-if="activeTab === 'event'">
        <Event />
      </template>
      <template v-if="activeTab === 'personal-address'">
        <Address />
      </template>
    </div>

    <!--       <v-card elevation="0">
                  <v-app-bar
                      color="white"
                      elevation="0"
                      >
                      <v-toolbar-title>{{$t('general.welcome')}}, {{user.name}}</v-toolbar-title>

                      <v-spacer></v-spacer>



                  </v-app-bar>
      <spinner v-if="loading"></spinner>
                  <v-card-text>
                          <div class="row">
                              <v-tabs
                                  v-if="!loading"
                                  background-color="white"
                                  color="deep-purple accent-4"
                                  left
                                  >

                                  <v-tab href="#tab-personal">{{$t('user.navbar.personal_info')}}</v-tab>
                                  <v-tab href="#tab-event">{{$t('user.navbar.my_event')}}</v-tab>
                                  <v-tab href="#tab-myvendors">{{$t('user.navbar.my_vendors')}}</v-tab>
                                  <v-tab href="#tab-quotes">{{$t('user.navbar.quote_requests')}}</v-tab>
                                  <v-tab href="#tab-orders">{{$t('user.navbar.my_orders')}}</v-tab>
                                  <v-tab href="#tab-message">{{$t('user.navbar.messages')}}</v-tab>
                                  <v-tab href="#tab-security" disabled>{{$t('user.navbar.security')}}</v-tab>
                                  <v-tab href="#tab-payments" disabled>{{$t('user.navbar.payments')}}</v-tab>


      <v-tab-item value="tab-personal">

          <div class="container">
              <v-card-text>
                  <v-tabs
                      v-if="!loading"
                      background-color="white"
                      color="black"
                      right
                      >
                      <v-tab href="#tab-personal-account">{{$t('user.navbar.account')}}</v-tab>
                      <v-tab href="#tab-personal-address">{{$t('user.navbar.address')}}</v-tab>

                      <v-tab-item value="tab-personal-account">
                          <v-form ref="form" v-model="valid" :lazy-validation="lazy" >
                              <v-card elevation="0">
                                  <v-card-subtitle>
                                      {{$t('user.personal_info_title')}}
                                  </v-card-subtitle>
                                  <v-card-text>
                                      <v-list>
                                          <v-list-item>
                                                  <v-spacer/>
                                                  <v-avatar class="mr-5" size="66px">
                                                      <img :src="user.avatar">
                                                  </v-avatar >
                                                  <Avatar/>

                                          </v-list-item>

                                          <v-list-item>
                                              <v-list-item-content>
                                                  &lt;!&ndash; Email &ndash;&gt;
                                                  <v-text-field
                                                  disabled
                                                  v-model="user.email"
                                                  label="Email"
                                                  ></v-text-field>
                                              </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item>
                                              <v-list-item-content>
                                                  &lt;!&ndash; Name &ndash;&gt;
                                                  <v-text-field
                                                  v-model="item.name"
                                                  label="Name"
                                                  :rules="[rules.required]"
                                                  @change="updateUser"
                                                  ></v-text-field>
                                              </v-list-item-content>
                                          </v-list-item>
                                      </v-list>
                                  </v-card-text>
                              </v-card>
                          </v-form>
                      </v-tab-item>
                      <v-tab-item value="tab-personal-address">
                      <v-card-text>
                          <Address/>
                      </v-card-text>

                      </v-tab-item>
                  </v-tabs>
              </v-card-text>
          </div>
      </v-tab-item>
      <v-tab-item value="tab-security">
      </v-tab-item>
      <v-tab-item value="tab-payments">
      </v-tab-item>
      <v-tab-item value="tab-event">
          <Event/>
      </v-tab-item>
      <v-tab-item value="tab-myvendors">
          <MyVendors/>
      </v-tab-item>
      <v-tab-item value="tab-quotes">
          <Quotes/>
      </v-tab-item>
      <v-tab-item value="tab-orders">
          <Orders/>
      </v-tab-item>
      <v-tab-item value="tab-message">
          <Message/>
      </v-tab-item>
                              </v-tabs>
                          </div>
                  </v-card-text>


             </v-card>-->

  </div>
</template>


<script>

import { mapGetters } from 'vuex'
import Avatar from '../../components/Avatar.vue';
import MlContainer from "@/components/layout/MlContainer";
//import { Edit2Icon } from "vue-feather-icons";
import Address from '../address/AddressPage.vue';
import Event from './myEventPage.vue';
/*import MyVendors from './myVendorsPage.vue';
import Quotes from './quotesPage.vue';
import Orders from './bookings/bookingsPage.vue';
import Message from '../MessagePage.vue'*/
import i18n from '@/i18n'

export default {
  components: {
    Avatar,
    MlContainer,
    //Edit2Icon,
    Address,
    Event,
    /*MyVendors,
    Orders,
    Quotes,
    Message,*/
  },
  metaInfo() {
    return {
      title: i18n.t('user.navbar.account'),
    }
  },
  middleware: 'auth',
  data() {
    return {
      item: {
        name: '',
        partner_email: ''
      },
      activeTab: 'event',
      valid: true,
      lazy: true,
      loading: false,
      selectedFile: null,
      file: null,
      rules: {
        required: value => !!value || 'Required.'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  mounted() {
    this.getItem();
  },
  computed: mapGetters({
    user: 'auth/user',
  }),
  methods: {
    updateUser() {
      let uri = '/api/user/' + this.item.id;
      this.$http.patch(uri, this.item)
        .then((response) => {
          this.$toast.success(response.data.message, "Success");
          this.$store.dispatch('auth/fetchUser');
        });
    },
    getItem() {
      this.loading = true;
      this.$http.get('api/user')
        .then(({ data }) => {
          this.item = data.data
        })
        .catch(error => {
          console.log('Error loading orders data: ' + error)
        }).finally(() =>
          this.loading = false,
        )
    },
    onFileSelected(event) {
      console.log(event);
      if (event == null) return;
      //this.selectedFile = event.target.files[0];
      this.selectedFile = event;
      this.onUpload();
    },
    onUpload() {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      let uri = '/api/user/' + this.item.id + '/avatar';
      this.$http.post(uri, formData, {
        onUploadProgress: uploadEvent => {
          console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      })
        .then((response) => {
          this.$toast.success(response.data.message, "Success")
          //this.$refs.inputFile.reset();
          this.file = null;
        }).finally(() => {
          this.getItem();
        });
    },
    destroyImage(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.onImageDelete(vId);

            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

          }],
        ]
      });
    },
    onImageDelete(id) {
      if (!this.item.id) return;
      let uri = '/api/user/' + this.item.id + '/avatar/' + id;
      this.$http.delete(uri)
        .then((response) => {
          this.item.images = response.data.images;
          this.$toast.success(response.data.message, "Success", { timeout: 1000 });
        });
    },
  }
}
</script>
